import React from "react";
import { Tabs, Tab, AppBar, Typography } from "@material-ui/core";
import TableToolbarComponent from "../TableComponent/TableToolbarComponent";
import PropTypes from "prop-types";

const DTabs = (props) => {
  
  const { className, value, onChange, tabsData, toolbarProps={}, textColor, tabsColor, orientation } = props;
  const tabValue = tabsData.find(tab => tab.value === value);
  const toolbar = toolbarProps.title && <TableToolbarComponent className={`tab__${value}`} {...toolbarProps} />;
  return <>
    <AppBar elevation={0} position="static" color={tabsColor ? tabsColor : "inherit"} className={className + "__tabs"}>
      <div className="main-content">
        {toolbarProps && toolbar}
        <Tabs
          orientation={orientation}
          value={value}
          onChange={onChange}
          indicatorColor={tabsColor === "primary" ? "secondary" : "primary"}
          textColor={textColor}
        >
          {tabsData.map(({ value, label, hideTab }) =>
            !hideTab && <Tab key={value} value={value} label={label} id={value} />
          )}
        </Tabs>
        {tabValue && tabValue.toolbar}
      </div>
      {tabValue && tabValue.toolbarBottom}
    </AppBar>
    {tabValue && !tabValue.hideTab
    && <Typography component="div" className={className + "__tab__content"}>{tabValue.content}</Typography>}
  </>
};

DTabs.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  tabsData: PropTypes.array,
  onChange: PropTypes.func,
  toolbarProps: PropTypes.object,
  textColor: PropTypes.string,
  tabsColor: PropTypes.string,
  orientation: PropTypes.string,
};

export default DTabs;
