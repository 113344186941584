import React from "react";
import { optionsVATScheme } from "../../Company/CreateEditVATScheme";
import { currencySymbols, formatDisplayDate, formatNumber, getFieldNameArray, replaceIndex } from "../../utils";
import { optionReportVAT } from "../../Company/fields-financial-info";
import FormComponent from "../../../components/controls/FormComponent";
import Form from "react-bootstrap/Form";
import {requiredUpdateValidator, requiredValidator} from "../../../components/controls/validators";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import toast from "../../../components/toast/toast";
import classNames from "clsx";
import {optionHeaderVAT} from "./components";

const fields = [
  "vat_number",
  // "registration_date",
  "deregistration_date",
  "report_vat",
  "scheme_name",
  "scheme_registration_date",
  "flat_rate_percentage",
];

export const generalVAT = (props) => {
  const {initData, isRegistered, tab, dataName, isExempt} = props;
  const regToExempt = (initData.is_vat_registered && isExempt);
  const exemptToReg = initData.vat_exempt_on_amazon && isRegistered;
  return [
    {
      id: "vat_exempt_on_amazon",
      label: <span>Are you VAT exempt on your Amazon Fees?</span>,
      type: "switch",
      showLabel: true,
        render: ({ disabled, customProps, extraProps, ...props }) => {
        const {values} = props;
        const {index, initId} = extraProps;
        const currentValues = values[dataName][index];
        const isReg = currentValues.is_vat_registered;
        const value = currentValues.vat_exempt_on_amazon;

        return (tab === "current"
            ? <FormComponent disabled={isReg} customProps={{mark: "No/Yes"}} {...props} />
            : (
              <>
                <Form.Label
                  column={false}
                  className={classNames("form-group__label-item", {"forms__headers--item": index})}
                >
                  {optionHeaderVAT.find((header) => header.id === initId)?.label}
                </Form.Label>
                <div className="wrap">
                  {
                    value
                      ? <CheckCircleOutlinedIcon fontSize="large"/>
                      : <HighlightOffIcon fontSize="large"/>
                  }
                </div>
              </>
            )
        )
      }
    },
    {
      id: "non_vat_registered",
      label: <span>Are you NON VAT registered?</span>,
      type: "switch",
      showLabel: true,
        render: ({ disabled, customProps, extraProps, ...props }) => {
        const { name, setFieldValue, values } = props;
        const {index, initId} = extraProps;
        const isReg = values[dataName][extraProps.index].is_vat_registered;
        const currentValues = values[dataName][index];
        const value = currentValues.non_vat_registered;

        const handleChange = (e) => {
          setFieldValue(name, e.target.checked);
          setFieldValue(`${dataName}.${index}.is_vat_registered`, !e.target.checked);
          setFieldValue(`${dataName}.${index}.vat_exempt_on_amazon`, false);
        };

        return (tab === "current"
            ? <FormComponent
                customProps={{ onChange: handleChange, mark: "No/Yes"}}
                disabled={isReg}
                {...props} />
            : (
              <>
                <Form.Label
                  column={false}
                  className={classNames("form-group__label-item", {"forms__headers--item": index})}
                >
                  {optionHeaderVAT.find((header) => header.id === initId)?.label}
                </Form.Label>
                <div className="wrap">
                  {
                    value
                      ? <CheckCircleOutlinedIcon fontSize="large"/>
                      : <HighlightOffIcon fontSize="large"/>
                  }
                </div>
              </>
            )
        )
      }
    },
    {
      id: "is_vat_registered",
      label: <span>Are you registered for VAT?</span>,
      type: "switch",
      showLabel: true,
      render: ({ customProps, disabled, extraProps, ...props }) => {
        const { name, setFieldValue, values } = props;
        const {index, initId} = extraProps;
        const isExempt = values[dataName][extraProps.index].vat_exempt_on_amazon;
        const isNonVat = values[dataName][extraProps.index].non_vat_registered;
        const currentValues = props.values[dataName][extraProps.index];

        const value = currentValues.is_vat_registered;

        const handleChange = (e) => {
          setFieldValue(name, e.target.checked);
          setFieldValue(`${dataName}.${index}.non_vat_registered`, !e.target.checked);

          if (e.target.checked) {
            if (!initData.is_vat_registered) {
              // form cleaning
              fields.forEach(item => setFieldValue(getFieldNameArray(dataName, index, item), null));
            } else {
              // add initialization data to the form
              fields.forEach(item => {
                item === "deregistration_date"
                  ? setFieldValue(getFieldNameArray(dataName, index, item), null)
                  : setFieldValue(getFieldNameArray(dataName, index, item), initData[item] || null);
              })
            }
          } else {
            // form cleaning
            fields.forEach(item => setFieldValue(getFieldNameArray(dataName, index, item), null))
          }
        };

        return (
          tab === "current"
            ? (
              <FormComponent
                customProps={{ onChange: handleChange, mark: "No/Yes" }}
                disabled={(isExempt || isNonVat)}
                {...props}
              />
            )
          : (<>
              <Form.Label
                column={false}
                className={classNames("form-group__label-item", {"forms__headers--item": index})}
              >
                {optionHeaderVAT.find((header) => header.id === initId)?.label}
              </Form.Label>
              <div className="wrap">
                {
                  value
                    ? <CheckCircleOutlinedIcon fontSize="large"/>
                    : <HighlightOffIcon fontSize="large"/>
                }
              </div>
            </>)
        )
      }
    },
    {
      id: "vat_number",
      label: "VAT Registration Number",
      type: "text",
      validator: isRegistered ? requiredValidator("VAT Registration Number is required!") : null,
      render: ({ extraProps, customProps, ...props }) => {
        const value = props.values[dataName][extraProps.index].vat_number;
        return (
          tab === "current"
            ? <FormComponent {...props} customProps={{type_number: "integer"}} />
            : <p className="historic-field">{value || "-"}</p>
        )
      }
    },
    {
      id: "registration_date",
      label: "Start date",
      type: "date",
      validator: (isRegistered || isExempt)
        ? requiredUpdateValidator(
          "Start date is required!",
          'Please change the start date!',
          (regToExempt || exemptToReg) && initData.registration_date)
        : null,
      render: ({customProps, extraProps, disabled, ...props}) => {
        const {setFieldValue, values, name} = props;
        const currentValues = values[dataName][extraProps.index];
        const valueDeRegDate = currentValues.deregistration_date;

        const onChange = (date) => {
          if (!valueDeRegDate || date < valueDeRegDate) {
            setFieldValue(name, date);
          } else {
            setFieldValue(name, null);
            toast.error(
              "Start date can't be later than the de-registration date!",
              { duration: 5000 }
              )
          }
        };
        const value = currentValues.registration_date;

        const isClearForm = !currentValues.is_vat_registered && !currentValues.vat_exempt_on_amazon;
        const disabledField = isClearForm && (initData.is_vat_registered ||initData.vat_exempt_on_amazon);
        return (
          tab === "current"
            ? (
              <FormComponent disabled={disabledField} customProps={{ onChange }} {...props} />
            )
            : <p className="historic-field">{value ? formatDisplayDate(value) : "-"}</p>
        )
      }
    },
    {
      id: "deregistration_date",
      label: "End date",
      type: "date",
      render: ({ disabled, customProps, extraProps, ...props }) => {
        const { values, setFieldValue, name } = props;
        const currentValues = values[dataName][extraProps.index];
        const valueRegDate = currentValues.registration_date;
        const value = currentValues.deregistration_date;

        const onChange = (date) => {
          if (!date || valueRegDate < date) {
            setFieldValue(name, date);
          } else {
            setFieldValue(name, null);
            toast.error(
              "End date can't be earlier than the registration date!",
            { duration: 5000 }
            )
          }
        };

        // disabled end date when they first turn on VAT REG / VAT EXEMPT
        // const initExempt = initData.vat_exempt_on_amazon;
        const initReg = initData.is_vat_registered;
        // const isNewExempt = !initExempt && currentValues.vat_exempt_on_amazon;
        const isNewReg = !initReg && currentValues.is_vat_registered;

        return (tab === "current"
          ? <FormComponent disabled={(!valueRegDate || isNewReg) && !currentValues.is_vat_registered } customProps={{ onChange }} {...props} />
          : <p className="historic-field">{value ? formatDisplayDate(value) : "-"}</p>
        )
      }
    },
  ]
};

export const vat = ({ initData, tab, dataName }) => [
  {
    id: "scheme_name",
    label: "VAT scheme",
    type: "RSelect",
    validator: requiredValidator("VAT scheme is required!"),
    render: ({ setFieldValue, customProps, extraProps, ...props }) => {
      const { name, values } = props;
      const {index} = extraProps;
      const onChange = (value) => {
        const name2 = getFieldNameArray(dataName, index, "flat_rate_percentage");
        setFieldValue(name, value);

        let flat_rate_percentage = initData[dataName].flat_rate_percentage;
        if (value.value === "IB" || value.value === "CA") {
          setFieldValue(name2, 0);
        } else {
          setFieldValue(name2, flat_rate_percentage)
        }
      };

      const value = values[dataName][index].scheme_name;

      const field = optionsVATScheme.find(item => item.value === value);

      return (tab === "historic"
        ? (
          <>
            <p className="historic-field">{field?.label || "-"}</p>
          </>
          )
        : <FormComponent customProps={{ onChange, options: optionsVATScheme }} {...props} />
      )
    }
  },
  {
    id: "scheme_registration_date",
    label: "Date you registered on this VAT scheme",
    type: "date",
    validator: requiredValidator("Date you registered on this VAT scheme is required!"),
    render: ({ extraProps, ...props }) => {
      const {index} = extraProps;
      const value = props.values[dataName][index].scheme_registration_date;
      return (tab === "historic"
          ? (
            <>
              <p className="historic-field">{value ? formatDisplayDate(value) : "-"}</p>
            </>
          )
          : <FormComponent {...props} />
      )
    }
  },
  {
    id: "flat_rate_percentage",
    label: "Flat rate percentage",
    type: "RNumber",
    placeholder: "5%",
    render: ({ values, customProps, disabled, validate, extraProps,...props }) => {
      const { setFieldValue, name } = props;
      const {index} = extraProps;
      const isHistoricTab = tab === "historic";
      const onChange = (val, prev) => {
        if (val.target.value < 0) {
          setFieldValue(name, 0);
        } else {
          setFieldValue(name, formatNumber(replaceIndex(val.target.value), prev));
        }
      };
      let isVATScheme = values && values[dataName][index].scheme_name;
      if (isVATScheme && !isVATScheme.value) {
        isVATScheme = optionsVATScheme.find(item => item.value === isVATScheme)
      }
      let isActive = isVATScheme && (isVATScheme.value === "FR-IB" || isVATScheme.value === "FR-CA");
      const isDisable = !isVATScheme || !isActive || (values && !values.global_vat_current[0].is_vat_registered);
      const value = values[dataName][index].flat_rate_percentage;
      return (isHistoricTab || !isDisable) && (
        tab === "historic"
          ? (
            <>
              <p className="historic-field">{value+"%" || "-"}</p>
            </>
          )
          : (
            <>
              <FormComponent
                customProps={{onChange, suffixValue: "%", hideLabel: false}}
                disabled={isHistoricTab}
                {...props}
              />
            </>
          )
        )
    }
  },
  {
    id: "report_vat",
    label: "How often do you report your VAT?",
    type: "RSelect",
    validator: requiredValidator("Period is required!"),
    render: ({ customProps, extraProps, ...props }) => {
      const {index} = extraProps;

      const value = props.values[dataName][index].report_vat;
      const field = optionReportVAT.find(item => item.value === value);
      return (tab === "historic"
          ? (
            <>
              <p className="historic-field">{field?.label || "-"}</p>
            </>
          )
          : <FormComponent customProps={{ options: optionReportVAT }} {...props} />
      )
    }
  },
];

export const profit = ({ currency }) => [
  {
    id: "profit_winner",
    label: "Winners",
    type: "RNumber",
    customProps: { beforeField: currencySymbols[currency] }
  },
  {
    id: "profit_looser",
    label: "Losers",
    type: "RNumber",
    customProps: { beforeField: currencySymbols[currency] }
  },
];
